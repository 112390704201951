import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import "./plugins/base";
import NotificationsHub from "./plugins/notificationsHub";
import vuetoasted from "./plugins/vuetoasted";
import printPdf from "./plugins/printPdf";

import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import authenticationManager from "./plugins/authenticationManager";
import VueGtm from "@gtm-support/vue2-gtm";

if (printPdf) printPdf();

Vue.use(VueTelInputVuetify, {
  vuetify
});
Vue.use(VueGtm, {
  id: "GTM-NNW4M7Q",
  defer: false,
  compatibility: false,
  nonce: "2726c7f26c",
  enabled: true,
  debug: true
});

Vue.config.productionTip = false;
Vue.use(NotificationsHub);
Vue.use(authenticationManager, router);

export const bus = new Vue();

new Vue({
  vuetoasted,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

import "@mdi/font/css/materialdesignicons.css";
