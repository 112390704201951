<template>
  <v-card-title class="text-h5">
    <p class="headline">{{ title }}</p>
    <v-spacer></v-spacer>
    <v-btn icon class="mr-4" @click="onCloseClick()">
      <v-icon size="30">
        mdi-window-close
      </v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
export default {
  name: "BaseCardTitle",
  props: {
    title: String
  },
  methods: {
    onCloseClick() {
      this.$emit("onCloseClick");
    }
  }
};
</script>
