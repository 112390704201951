import axios from "axios";
import settings from "../../settings";
axios.defaults.baseURL = settings.baseUrl;

export const setUserProfile = ({ commit }, profile) => {
  return new Promise((resolve, reject) => {
    axios
      .put("userprofile", profile)
      .then(response => {
        commit("SET_USER_PROFILE", profile);
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getUserProfile = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios
      .get("userprofile")
      .then(resp => {
        const userprofile = resp.data;
        commit("SET_USER_PROFILE", userprofile);
        commit("SET_COUNT_USER_DEVICES", userprofile.devices.length);
        resolve(userprofile);
      })
      .catch(err => {
        commit("SET_USER_PROFILE", undefined);
        reject(err);
      });
  });
};

export const generateNewApiKey = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios
      .post("userprofile/apikey")
      .then(resp => {
        const apiKey = resp.data;
        commit("SET_USER_PROFILE_API_KEY", apiKey);
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const updateWebhook = ({ commit }, webhook) => {
  let webhookBody = {
    webhook: webhook.webhook,
    webhookHeaderName: webhook.webhookHeaderName,
    webhookHeaderValue: webhook.webhookHeaderValue
  };

  if (webhook.webhook === "") {
    webhookBody.webhook = null;
  }
  if (
    webhookBody.webhookHeaderName === "" ||
    webhookBody.webhookHeaderValue === ""
  ) {
    webhookBody.webhookHeaderName = null;
    webhookBody.webhookHeaderValue = null;
  }

  return new Promise((resolve, reject) => {
    axios
      .put("customer/webhook", webhookBody)
      .then(() => {
        commit("SET_USER_PROFILE_WEBHOOK", webhookBody);
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const sendDocumentSetPost = ({ commit }, url) => {
  let body = {
    url: url
  };
  if (url.length === 0) {
    body.url = null;
  }
  return new Promise((resolve, reject) => {
    axios
      .put("customer/signedDocumentsWebhook", body)
      .then(() => {
        commit("SET_USER_PROFILE_SEND_DOCUMENT_SET_POST", url);
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getUserApiSettings = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios
      .get("userprofile/apiSettings")
      .then(resp => {
        const apiSettings = resp.data;
        commit("SET_USER_PROFILE_SETTINGS", apiSettings);
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const setUserLevel = ({ commit }, toSee = true) => {
  return new Promise(resolve => {
    commit("SET_USER_LEVEL", toSee);
    resolve();
  });
};
export const updateCompanyLogo = ({ commit }, companyLogo) => {
  let companyLogoBody = {
    b64LogoContent: companyLogo
  };
  if (companyLogo.length === 0) {
    companyLogoBody.b64LogoContent = null;
  }
  return new Promise((resolve, reject) => {
    axios
      .put("userprofile/companyLogo", companyLogoBody)
      .then(() => {
        commit("SET_USER_PROFILE_COMPANY_LOGO", companyLogo);
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const deleteCompanyLogo = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete("userprofile/companyLogo")
      .then(() => {
        commit("DELETE_USER_PROFILE_COMPANY_LOGO");
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
export const getCompanyLogo = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios
      .get("userprofile/companyLogo")
      .then(resp => {
        const companyLogo = resp.data.result && resp.data.result.b64LogoContent;
        commit("SET_COMPANY_LOGO", companyLogo);
        resolve(companyLogo);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getUserCredits = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios
      .get("userprofile/consumedCredits")
      .then(resp => {
        const result = resp.data;
        commit("SET_CONSUMED_CREDITS", result.consumedCredits);
        commit("SET_LIMIT_CREDITS", result.totalCredits);
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
