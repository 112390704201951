import { initialUserState } from "./state";

export const SET_USER_PROFILE = (state, payload) => {
  state.userProfile = payload;
};
export const SET_COUNT_USER_DEVICES = (state, payload) => {
  state.countUserDevices = payload;
};
export const SET_USER_PROFILE_API_KEY = (state, payload) => {
  state.userProfileSettings.apiKey = payload;
};
export const SET_USER_PROFILE_WEBHOOK = (state, payload) => {
  state.userProfileSettings.webhook = payload.webhook;
  state.userProfileSettings.webhookHeaderName = payload.webhookHeaderName;
  state.userProfileSettings.webhookHeaderValue = payload.webhookHeaderValue;
};
export const SET_USER_PROFILE_SEND_DOCUMENT_SET_POST = (state, payload) => {
  state.userProfileSettings.sendDocumentSetPost = payload;
};
export const SET_USER_PROFILE_SETTINGS = (state, payload) => {
  state.userProfileSettings = payload;
};
export const SET_USER_LEVEL = (state, payload) => {
  state.userLevel = payload;
};
export const SET_USER_PROFILE_COMPANY_LOGO = (state, payload) => {
  state.companyLogo = payload;
};
export const DELETE_USER_PROFILE_COMPANY_LOGO = state => {
  state.companyLogo = null;
};
export const SET_COMPANY_LOGO = (state, payload) => {
  state.companyLogo = payload;
};
export const SET_CONSUMED_CREDITS = (state, payload) => {
  state.consumedCredits = payload;
};
export const SET_LIMIT_CREDITS = (state, payload) => {
  state.limitCredits = payload;
};
export const RESET_TEAMS = state => {
  Object.assign(state, initialUserState);
};
