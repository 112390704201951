export const userProfile = state => {
  return state.userProfile;
};
export const userDeviceFav = state => {
  if (state.userProfile != undefined) {
    if (state.userProfile.devices.length === 1) {
      return state.userProfile.devices[0];
    } else {
      var deviceId = state.userProfile.deviceId;
      for (var i = 0; i < state.userProfile.devices.length; i++) {
        if (state.userProfile.devices[i].deviceId === deviceId) {
          return state.userProfile.devices[i];
        }
      }
    }
  }
  return undefined;
};
export const countUserDevices = state => {
  return state.countUserDevices;
};
export const userProfileWebhook = state => {
  return state.userProfileSettings != undefined
    ? state.userProfileSettings.webhook
    : "";
};
export const userProfileWebhookHeader = state => {
  return state.userProfileSettings != undefined
    ? state.userProfileSettings.webhookHeaderName
    : "";
};
export const userProfileWebhookValue = state => {
  return state.userProfileSettings != undefined
    ? state.userProfileSettings.webhookHeaderValue
    : "";
};
export const userProfileSendDocumentSetPost = state => {
  return state.userProfileSettings != undefined
    ? state.userProfileSettings.sendDocumentSetPost
    : "";
};
export const userProfileApiKey = state => {
  return state.userProfileSettings != undefined
    ? state.userProfileSettings.apiKey
    : "";
};
export const isLoggedUserAdmin = state => {
  if (state.userProfile !== undefined) {
    return state.userProfile.isCustomerAdmin;
  }
  return false;
};
export const credits = state => {
  var limitCredits = state.limitCredits;
  var consumedCredits = state.consumedCredits;
  if (limitCredits != undefined && consumedCredits != undefined) {
    if (limitCredits == -1) {
      return "Ilimitados";
    }
    var availableCredits = limitCredits - consumedCredits;
    if (availableCredits == limitCredits || availableCredits == 0) {
      return availableCredits;
    }
    return availableCredits + " / " + limitCredits;
  }
  return null;
};
