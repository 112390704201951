<template>
  <UpgradePurchase></UpgradePurchase>
</template>
<script>
import UpgradePurchase from "../../../views/upgradePurchase/UpgradePurchase";
export default {
  name: "FullDialog",
  components: { UpgradePurchase },
  props: {},
  data: () => ({}),
  computed: {},
  methods: {}
};
</script>

<style lang="scss"></style>
