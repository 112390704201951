<template>
  <v-row class="ml-1 mb-4">
    <v-icon size="32" class="secondary--text text-small">{{ icon }}</v-icon>
    <h2 class="secondary--text ml-2 text-small">
      {{ title }}
    </h2>
  </v-row>
</template>
<script>
export default {
  name: "BaseBreadcrumb",
  props: {
    title: String,
    icon: String
  }
};
</script>

<style>
@media (max-width: 465px) {
  .text-small {
    font-size: 1.1rem !important;
    line-height: 2rem;
  }
  .text-small svg {
    height: 24px !important;
    width: 24px !important;
  }
}
</style>
