<template>
  <v-dialog
    scrollable
    persistent
    v-model="seeUpgradePurchase"
    :no-click-animation="true"
    max-width="1200"
  >
    <v-card class="upgrade-purchase h-100">
      <v-card-title class="text-h5">
        <span class="headline">{{ "PLANES & PRECIOS" }}</span>
      </v-card-title>
      <v-card-text>
        <div class="subtitle">Obtener más firmas y ventajas extra</div>
        <div class="flex-center listUpgradePurchaseCard h-100">
          <template v-for="(plan, i) in availablePlans">
            <UpgradePurchaseCard
              :idPlan="plan.membershipPlanCode"
              :title="plan.membershipPlanName"
              :recommended="plan.membershipPlanCode === recommendedPlan"
              :current="plan.membershipPlanCode === currentPlanId"
              :cost="{
                currency: plan.price >= 0,
                unit: plan.price >= 0 ? parseInt(plan.price) : 'Contáctenos',
                decimal: ParseFractionalPart(
                  plan.price.toString(),
                  plan.monthAmount
                )
              }"
              :purchaseProps="[
                `${plan.creditAmount} firmas`,
                `Renovación cada ${MonthOrYear(plan.monthAmount)}`,
                ParseTextCost(plan.price)
              ]"
              :key="i"
              @purchase="onPurchase"
            >
            </UpgradePurchaseCard>
          </template>
        </div>
        <v-btn
          @click="onCloseUpgradePurchase"
          class="closeUpgradePurchaseButton"
          >Cerrar</v-btn
        >
      </v-card-text>
    </v-card>

    <v-dialog
      scrollbale
      persistent
      v-model="purchasingModal"
      :no-click-animation="true"
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          <span class="headline">Efectuar Pago</span>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <Checkout
            @close="purchasingModal = false"
            @purchase="
              purchaseSuccess = true;
              purchasingModal = false;
              onCloseUpgradePurchase();
            "
            :purchasingItem="purchasingItem"
          ></Checkout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="purchaseSuccess" max-width="550px">
      <Success
        :purchasingItem="purchasingItem"
        @close="purchaseSuccess = false"
      ></Success>
    </v-dialog>
  </v-dialog>
</template>
<script>
import UpgradePurchaseCard from "./UpgradePurchaseCard.vue";
import Checkout from "../purchase/Checkout";
import Axios from "axios";
import { GoToUrl } from "../../utils/utils";
import { RECOMMENDED_PLAN } from "@/utils/constants";
import { ParseFractionalPart, MonthOrYear, ParseTextCost } from "@/utils/utils";
import Success from "@/views/purchase/Success.vue";

export default {
  mounted: () => {},
  name: "UpgradePurchase",
  components: {
    UpgradePurchaseCard,
    Checkout,
    Success
  },
  props: {},
  data: () => ({
    ParseFractionalPart: ParseFractionalPart,
    MonthOrYear: MonthOrYear,
    ParseTextCost: ParseTextCost,
    availablePlans: [],
    creditPackages: [],
    currentPlanId: null,
    purchasingItem: null,
    purchasingModal: null,
    purchaseSuccess: false
  }),
  computed: {
    seeUpgradePurchase() {
      return this.$store.state.seeUpgradePurchase;
    },
    recommendedPlan() {
      return RECOMMENDED_PLAN;
    }
  },
  methods: {
    onCloseUpgradePurchase() {
      this.$store.dispatch("setSeeUpgradePurchase", false);
    },
    onPurchase(idPlan) {
      const plan = this.availablePlans.find(
        plan => plan.membershipPlanCode === idPlan
      );
      if (plan.price >= 0) {
        this.purchasingItem = plan;
        this.purchasingModal = !!plan;
      } else GoToUrl("https://firmar.online/contactanos/");
    }
  },
  beforeRouteEnter(to, from, next) {
    const getCurrentPlan = Axios.get("purchase/currentPlan");
    const getAvailablePlans = Axios.get("purchase/availablePlans");
    Promise.all([getCurrentPlan, getAvailablePlans]).then(
      ([currentPlanResponse, availablePlansResponse]) => {
        next(vm => {
          const currentPlanId = currentPlanResponse.data;
          const availablePlans = availablePlansResponse.data;
          vm.currentPlanId = currentPlanId;
          const memberShip = availablePlans.find(
            plan => plan.membershipPlanCode == currentPlanId
          );
          vm.$store.dispatch(
            "setMembershipPlanName",
            memberShip && memberShip.membershipPlanName
          );
          vm.$store.dispatch("setMembershipPlanCode", currentPlanId);
          if (memberShip) {
            vm.$store.dispatch("user/setUserLevel", memberShip.userLevel);
          }
        });
      }
    );
  }
};
</script>

<style lang="scss"></style>
