<template>
  <v-col lg="7" class="primary d-none d-lg-flex align-center justify-center">
    <div class="d-none d-lg-block">
      <div class="d-flex align-center pa-10">
        <div>
          <h2 class="display-1 white--text font-weight-medium">
            Firma digital de confianza para empresas y particulares
          </h2>
          <h6 class="subtitle-1 mt-4 white--text op-5 font-weight-regular">
            Firmar Online es un servicio de firma electrónica de documentos PDF
            que ofrece garantías jurídicas y seguridad técnica.
          </h6>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "BaseAuthenticationAux"
};
</script>
