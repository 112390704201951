<template>
  <div v-if="isEnvironmentBannerVisible" class="environment-banner">
    Entorno de<br />Integración
  </div>
</template>
<script>
import settings from "../../../settings";

export default {
  name: "EnvironmentBanner",
  components: {},
  props: {},
  data: () => ({}),
  computed: {
    isEnvironmentBannerVisible() {
      return settings.environment.toLowerCase() === "sandbox";
    }
  },
  methods: {}
};
</script>

<style scoped>
.environment-banner {
  display: block;
  width: 300px;
  top: 25px;
  left: -97px;
  text-align: center;
  padding: 10px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #f5f5f5;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: 9999;
  position: fixed;
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  background: rgba(255, 0, 0, 0.7);
  pointer-events: none;
}
</style>
