import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

let purchaseStore = {
  namespaced: true,
  state: {
    purchasingItem: null
  },
  getters,
  mutations,
  actions
};

export default purchaseStore;
