export default function() {
  // se ha redirigido a la solicitud configurada
  window.isRedirect = false;

  // mantener la sesión abierta
  window.StatusMaintainSession = true;

  // ha surgido un error al firmar
  window.hasErrorSign = false;

  //ésta variable la usará la aplicación Windows para saber en qué estado está:
  //10: pantalla de login
  //20: logged in
  //30: recibido PDF
  //40: PDF firmado (app impresora virtual sale)

  window.StatusWebappFO = 10;

  //estamos enganchados a una app híbrida de santi?
  window.HybridAppHook = false;

  //PDF recibido, lo que nos interesa
  window.pdfReceived = "";

  //si nos envían un 1 en ésta función, queremos que la aplicación híbrida se "enganche" a la webapp
  //es decir, le sirve a Javi para saber, poner algún flag por ahí o lo que sea que se está usando su webapp enlazado a la HybridApp
  window.DoHybridAppHook = function(paramIn) {
    if (paramIn == 1) {
      window.HybridAppHook = true;
      document.querySelector("html") &&
        document.querySelector("html").classList.add("overflow-hidden");
    }
  };

  window.DoLogIn = function() {
    window.StatusWebappFO = 20;
  };

  window.DoHybridAppReceivePDF = function(pdfB64In, pdfName) {
    if (pdfB64In && pdfB64In.length) {
      window.pdfReceived = pdfB64In;
      window.pdfName = pdfName;
      window.StatusWebappFO = 30;
    }
  };

  window.DoSigned = function() {
    window.StatusWebappFO = 40;
  };
}
