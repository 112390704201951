import Vue from "vue";
import axios from "axios";
import { fabric } from "fabric";

export function InitFabricObjects() {
  fabric.Object.prototype.borderColor = "transparent";
  fabric.Object.prototype.cornerColor = "#2383B6";
  fabric.Object.prototype.cornerSize = "15";
  fabric.Object.prototype.cornerStyle = "circle"; //default is rect
  fabric.Object.prototype.setControlsVisibility({
    tl: false, //top-left
    mt: false, // middle-top
    tr: false, //top-right
    ml: false, //middle-left
    mr: false, //middle-right
    bl: false, // bottom-left
    mb: false, //middle-bottom
    br: true, //bottom-right
    mtr: false //middle rop rotation
  });

  // Enable scroll functionallity for mobiles
  var defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
  fabric.util.object.extend(fabric.Canvas.prototype, {
    _onTouchStart: function(e) {
      var target = this.findTarget(e);
      // if allowTouchScrolling is enabled, no object was at the
      // the touch position and we're not in drawing mode, then
      // let the event skip the fabricjs canvas and do default
      // behavior
      if (this.allowTouchScrolling && !target && !this.isDrawingMode) {
        // returning here should allow the event to propagate and be handled
        // normally by the browser
        return;
      }

      // otherwise call the default behavior
      defaultOnTouchStartHandler.call(this, e);
    }
  });
}

export async function FileToB64(file) {
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = error => reject(error);
    });
  return await toBase64(file);
}

export function FileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export function DownloadFile(file, fileName) {
  const linkSource = file;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
export function DataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
export function Base64ToArrayBuffer(base64) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export function FileToBinaryString(file, slice = 0) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function(e) {
      resolve(slice ? e.target.result.slice(0, slice) : e.target.result);
    };
    reader.onerror = function(e) {
      reject("Error : " + e.type);
    };
    reader.readAsBinaryString(file);
  });
}

export function AssignObj(objDefault, objSrc) {
  if (!!objDefault && !!objSrc) {
    for (const [key, value] of Object.entries(objSrc)) {
      if (!value) objSrc[key] = objDefault[key];
    }
  }
  return objSrc;
}

export function AssignByAnotherObj(objDefault, objSrc, anotherObj) {
  if (!!objDefault && !!objSrc && !!anotherObj) {
    for (const [key, value] of Object.entries(objSrc)) {
      if (!value || (value === anotherObj[key] && value !== objDefault[key]))
        objSrc[key] = objDefault[key];
    }
  }
  return objSrc;
}

export function EqualToNull(objCompare, objSrc) {
  let objOut = {};
  if (!!objCompare && !!objSrc) {
    for (const [key, value] of Object.entries(objSrc)) {
      objOut[key] = !value || value === objCompare[key] ? null : value;
    }
  }
  return objOut;
}

export function ScaleDataURL(dataURL, ratio) {
  return new Promise(done => {
    var img = new Image();
    img.onload = () => {
      let canvas, ctx;
      const _width =
        img.width > img.height * ratio ? img.width : img.height * ratio;
      const _height =
        img.width > img.height * ratio ? img.width / ratio : img.height;

      canvas = document.createElement("canvas");
      canvas.height = _height;
      canvas.width = _width;
      ctx = canvas.getContext("2d");
      ctx.drawImage(
        img,
        _width / 2 - img.width / 2,
        _height / 2 - img.height / 2
      );
      const finalDataURL = canvas.toDataURL();
      done(finalDataURL.replace("png", "jpg"));
    };
    img.src = dataURL;
  });
}

export function GoToUrl(url, newTab = true) {
  window.open(url, newTab ? "_blank" : void 0).focus();
}

export function ParseFractionalPart(value, every = 1) {
  const parsed = value.split(".")[1];
  return parsed ? parsed + "/" + MonthOrYear(every) : "00";
}

export function MonthOrYear(every) {
  return every === 1 ? "mes" : "año";
}

export function ParseTextCost(cost) {
  return cost > 0 ? cost + "€" : cost === 0 ? "Gratuito" : "Contáctenos";
}

export function GetFileManager() {
  return {
    uploadFile: () => {},
    downloadFile: (url, saveAsFileName) => {
      let downloadRequest = new Vue(); //use new Vue instance as an event bus
      downloadRequest.progress = 0;
      downloadRequest.downloading = false;
      downloadRequest.blob = null;

      const CancelToken = axios.CancelToken;
      downloadRequest.downloading = true;
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: progressEvent => {
          downloadRequest.progress = progressEvent.loaded / progressEvent.total;
          downloadRequest.$emit("download-progress", downloadRequest.progress);
        },
        cancelToken: new CancelToken(c => (downloadRequest.cancel = c))
      })
        .then(response => {
          downloadRequest.blob = new Blob([response.data]);
          downloadRequest.$emit("file-downloaded", downloadRequest.blob);
          if (saveAsFileName) {
            //saveAs(downloadRequest.blob, saveAsFileName);
          }
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            let message =
              typeof error.data === "string" || error.data instanceof String
                ? error.data
                : error.message;
            downloadRequest.$emit("download-error", message);
          } else {
            downloadRequest.$emit("download-canceled");
          }
        })
        .finally(() => {
          downloadRequest.downloading = false;
          downloadRequest.$emit("download-ended");
        });

      return downloadRequest;
    }
  };
}

export function DownloadBinaryZip(file, name) {
  const url = window.URL.createObjectURL(
    new Blob([file], { type: "application/zip" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name + ".zip");
  document.body.appendChild(link);
  link.click();
}

export function SetAddDay(date) {
  const outDate = new Date(date);
  outDate.setHours(24);
  return new Date(outDate).toLocaleDateString("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}

export function SetMinusDay(date) {
  const outDate = new Date(date);
  outDate.setHours(-24);
  return new Date(outDate).toLocaleDateString("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}
