const state = {
  userProfile: undefined,
  countUserDevices: null,
  userProfileSettings: undefined,
  userLevel: "",
  companyLogo: undefined,
  consumedCredits: undefined,
  limitCredits: undefined
};
export const initialUserState = { ...state };

export default state;
