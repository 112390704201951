import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import settings from "../settings";
import axios from "axios";
import PurchaseStore from "./purchase";
import userStore from "./user";
Vue.use(Vuex);

axios.defaults.baseURL = settings.baseUrl;

const getDefaultState = () => {
  return {
    Sidebar_drawer: null, //wrappixel stateº
    historicSignFromAppItems: [],
    historicPSCItems: [],
    countHistoricPSCItems: null,
    countFlowItems: null,
    countSignFromAppItems: null,
    mailTemplates: undefined,
    flows: [],
    selected_flow: null,
    printerConfiguration: null,
    lastUserAction: "",
    newPSCItemParticipants: [],
    seeUpgradePurchase: false,
    membershipPlanName: "",
    membershipPlanCode: "",
    myDocuments: [],
    countMyDocumentsItems: null,
    resources: {},
    bulkdownloads: [],
    countBulkDownloadsItems: null,
    CSVExport: [],
    correspondenceCombinationItems: [],
    countCorrespondenceCombinationItems: null,
    myCompany: undefined,
    companyTeams: [],
    companyUsers: [],
    activeTeamTokenId: undefined
  };
};

const state = getDefaultState();

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  modules: {
    purchase: PurchaseStore,
    user: userStore
  },
  state: state,

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
      this.commit("user/RESET_TEAMS");
    },

    // mutations by wrappixel: https://wrappixel.com/demos/vuejs-admin-templates/materialpro-vuetify-admin/main/dashboards/analytical
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    // end of mutations by wrappixel

    SET_PSC_SETTINGS_MAIL_TEMPLATES(state, payload) {
      state.mailTemplates = payload;
    },

    SET_LAST_USER_ACTION(state, payload) {
      state.lastUserAction = payload;
    },
    SET_HISTORIC_PSC_ITEMS(state, payload) {
      state.historicPSCItems = payload;
    },
    SET_HISTORIC_SIGN_FROM_APP_ITEMS(state, payload) {
      state.historicSignFromAppItems = payload;
    },
    SET_HISTORIC_MY_DOCUMENTS_ITEMS(state, payload) {
      state.myDocuments = payload;
    },

    SET_COUNT_CORRESPONDENCE_COMBINATION_ITEMS(state, payload) {
      state.countCorrespondenceCombinationItems = payload;
    },

    SET_CORRESPONDENCE_COMBINATION_ITEMS(state, payload) {
      state.correspondenceCombinationItems = payload;
    },

    SET_COUNT_HISTORIC_BULK_ITEMS(state, payload) {
      state.countBulkDownloadItems = payload;
    },
    SET_BULK_ITEMS(state, payload) {
      state.bulkdownloads = payload;
    },
    SET_BULK(state, payload) {
      state.bulkSelected = payload;
    },

    SET_COUNT_HISTORIC_PSC_ITEMS(state, payload) {
      state.countHistoricPSCItems = payload;
    },
    SET_COUNT_HISTORIC_FLOW_ITEMS(state, payload) {
      state.countFlowItems = payload;
    },
    SET_COUNT_MY_DOCUMENTS_ITEMS(state, payload) {
      state.countMyDocumentsItems = payload;
    },
    SET_COUNT_SIGN_FROM_APP_ITEMS(state, payload) {
      state.countSignFromAppItems = payload;
    },
    SET_SEE_UPGRADE_PURCHASE(state, payload) {
      state.seeUpgradePurchase = payload;
    },
    SET_MEMBERSHIP_PLAN_NAME(state, payload) {
      state.membershipPlanName = payload;
    },
    SET_MEMBERSHIP_PLAN_CODE(state, payload) {
      state.membershipPlanCode = payload;
    },

    SET_RESOURCES(state, payload) {
      state.resources[payload.language] = payload.resources;
    },
    SET_PRINTER_CONFIGURATION(state, payload) {
      state.printerConfiguration = payload;
    },
    DELETE_FLOWS() {},
    POST_FLOWS(state, payload) {
      state.selected_flow = payload;
    },
    PUT_FLOWS(state, payload) {
      state.selected_flow = payload;
    },
    GET_FLOWS(state, payload) {
      state.flows = payload;
    },
    GET_FLOW(state, payload) {
      state.selected_flow = payload;
    },
    SET_MAIL_TEMPLATES(state, payload) {
      state.mailTemplates = payload;
    },
    SET_NEW_PSC_ITEM_PARTICIPANTS(state, payload) {
      state.newPSCItemParticipants = payload;
    },
    SET_MY_COMPANY(state, payload) {
      state.myCompany = payload;
    },
    SET_COMPANY_TEAMS(state, payload) {
      state.companyTeams = payload;
    },
    SET_COMPANY_USERS(state, payload) {
      state.companyUsers = payload;
    },
    SET_ACTIVE_TEAM_TOKEN_ID(state, payload) {
      state.activeTeamTokenId = payload;
    }
  },

  actions: {
    logout({ commit }) {
      commit("RESET_STATE");
    },

    cancelUserInvitation({ commit, dispatch }, email) {
      return new Promise((resolve, reject) => {
        axios
          .post("userprofile/" + email + "/InvitationCancel")
          .then(() => {
            dispatch("getCompanyUsers");
            resolve();
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "cancelUserInvitation");
      });
    },

    resendUserInvitation({ commit }, email) {
      return new Promise((resolve, reject) => {
        axios
          .post("userprofile/" + email + "/InvitationResend")
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "resendUserInvitation");
      });
    },

    putAdminUsers({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put("User/CustomerAdmin", data.emails)
          .then(() => {
            dispatch("getCompanyUsers");
            dispatch("user/getUserProfile");
            resolve();
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "putAdminUsers");
      });
    },

    addTeam({ commit, dispatch }, team) {
      return new Promise((resolve, reject) => {
        axios
          .post("team", team)
          .then(resp => {
            const data = resp.data;
            dispatch("getCompanyTeams");
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "addTeam");
      });
    },

    getCompanyUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("customer/users")
          .then(resp => {
            const data = resp.data;
            commit("SET_COMPANY_USERS", data);
            resolve(data);
          })
          .catch(err => {
            commit("SET_COMPANY_USERS", []);
            reject(err);
          });
      });
    },

    getCompanyTeams({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("customer/teams")
          .then(resp => {
            const data = resp.data;
            commit("SET_COMPANY_TEAMS", data);
            resolve(data);
          })
          .catch(err => {
            commit("SET_COMPANY_TEAMS", []);
            reject(err);
          });
      });
    },
    putTeamUsers({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put("team/" + data.teamTokenId + "/users", data.emails)
          .then(() => {
            dispatch("getCompanyUsers");
            dispatch("user/getUserProfile");
            commit("SET_ACTIVE_TEAM_TOKEN_ID", undefined);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getMyCompany({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("customer")
          .then(resp => {
            const data = resp.data;
            commit("SET_MY_COMPANY", data);
            resolve(data);
          })
          .catch(err => {
            commit("SET_MY_COMPANY", undefined);
            reject(err);
          });
      });
    },

    putMyCompany({ commit }, company) {
      return new Promise((resolve, reject) => {
        axios
          .put("customer", company)
          .then(resp => {
            commit("SET_MY_COMPANY", company);
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    putTeam({ commit, dispatch }, team) {
      return new Promise((resolve, reject) => {
        axios
          .put("team/" + team.teamTokenId, team)
          .then(resp => {
            resolve(resp);
            dispatch("getCompanyTeams");
            dispatch("user/getUserProfile");
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "putTeam");
      });
    },

    getCorrespondenceCombinations({ commit }, urlParams) {
      var params = new URLSearchParams([
        ["limit", urlParams.limit],
        ["offset", urlParams.offset],
        ["teams", urlParams.teams]
      ]);
      return new Promise((resolve, reject) => {
        axios
          .get("mailmerge", { params })
          .then(resp => {
            const flow = resp.data;
            commit(
              "SET_COUNT_CORRESPONDENCE_COMBINATION_ITEMS",
              Number(resp.headers["x-total-count"])
            );
            commit("SET_CORRESPONDENCE_COMBINATION_ITEMS", flow);

            resolve(flow);
          })
          .catch(err => {
            commit("SET_COUNT_CORRESPONDENCE_COMBINATION_ITEMS", 0);
            reject(err);
          });
      });
    },

    postCorrespondenceCombination({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post("mailMerge", item)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "postCorrespondenceCombination");
      });
    },

    getCorrespondenceCombination({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("mailMerge/" + id)
          .then(resp => {
            const item = resp.data.result;
            resolve(item);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "getCorrespondenceCombination");
      });
    },

    deleteFlow({ commit }, flowTokenId) {
      return new Promise((resolve, reject) => {
        axios
          .delete("flow/" + flowTokenId)
          .then(resp => {
            const flows = resp.data;
            commit("DELETE_FLOWS", flows);
            resolve(flows);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    postFlow({ commit }, flow) {
      return new Promise((resolve, reject) => {
        axios
          .post("flow", flow)
          .then(resp => {
            const flow = resp.data;
            commit("POST_FLOWS", flow);
            resolve(flow);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    putFlow({ commit }, flow) {
      return new Promise((resolve, reject) => {
        axios
          .put("flow/" + flow.flowTokenId, flow)
          .then(resp => {
            const flow = resp.data;
            commit("PUT_FLOWS", flow);
            resolve(flow);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getFlows({ commit }, urlParams) {
      var params = new URLSearchParams([
        ["limit", urlParams.limit],
        ["offset", urlParams.offset]
      ]);

      return new Promise((resolve, reject) => {
        axios
          .get("flow", { params })
          .then(resp => {
            const flows = resp.data;
            commit("GET_FLOWS", flows);
            commit(
              "SET_COUNT_HISTORIC_FLOW_ITEMS",
              Number(resp.headers["x-total-count"])
            );
            resolve(flows);
          })
          .catch(err => {
            commit("SET_COUNT_HISTORIC_FLOW_ITEMS", 0);
            reject(err);
          });
      });
    },

    getBulkdownloads({ commit }, urlParams) {
      var params = new URLSearchParams([
        ["limit", urlParams.limit],
        ["offset", urlParams.offset]
      ]);

      return new Promise((resolve, reject) => {
        axios
          .get("bulkdownload", { params })
          .then(resp => {
            const flow = resp.data;
            commit(
              "SET_COUNT_HISTORIC_BULK_ITEMS",
              Number(resp.headers["x-total-count"])
            );
            commit("SET_BULK_ITEMS", flow);

            resolve(flow);
          })
          .catch(err => {
            commit("SET_COUNT_HISTORIC_BULK_ITEMS", 0);
            reject(err);
          });
      });
    },

    postBulkdownload({ commit }, bulkRequest) {
      return new Promise((resolve, reject) => {
        axios
          .post("bulkdownload", bulkRequest)
          .then(resp => {
            const flow = resp.data;
            console.log(commit);
            resolve(flow);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getBulkdownload({ commit }, bulkId) {
      return new Promise((resolve, reject) => {
        axios
          .get("bulkdownload/" + bulkId)
          .then(resp => {
            const flow = resp.data;
            console.log(commit);
            resolve(flow);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getFileBulkdownload({ commit }, bulkId) {
      return new Promise((resolve, reject) => {
        axios
          .get("bulkdownload/file/" + bulkId, { responseType: "blob" })
          .then(resp => {
            const flow = resp.data;
            console.log(commit);
            resolve(flow);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    inviteNewUser({ commit, dispatch }, newUser) {
      return new Promise((resolve, reject) => {
        axios
          .post("userprofile/inviteNewUser", newUser)
          .then(resp => {
            dispatch("getCompanyUsers");
            const response = resp.data;
            console.log(commit);
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getFlow({ commit }, flowTokenId) {
      return new Promise((resolve, reject) => {
        axios
          .get("flow/" + flowTokenId)
          .then(resp => {
            const flow = resp.data;
            resolve(flow);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "getFlow");
      });
    },

    getMailtemplates({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("pscsettings/mailtemplates")
          .then(resp => {
            const mailtemplates = resp.data.result;
            commit("SET_MAIL_TEMPLATES", mailtemplates);
            resolve(mailtemplates);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    updateMailtemplates({ commit }, mailTemplates) {
      return new Promise((resolve, reject) => {
        axios
          .put("pscsettings/mailtemplates", mailTemplates)
          .then(() => {
            commit("SET_PSC_SETTINGS_MAIL_TEMPLATES", mailTemplates);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    subscribeToWebhookGroup({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post("signalR/AddTrace/" + id)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "subscribeToWebhookGroup");
      });
    },

    removeFromWebhookGroup({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post("SignalR/RemoveTrace/" + id)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "removeFromWebhookGroup");
      });
    },

    testWebhook({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post("psc/webhook/test/" + id)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "testWebhook");
      });
    },
    convertWordToPdf({ commit }, body) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", body.file);
        axios
          .post("mailmerge/convertwordtopdf", formData, {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "convertWordToPdf");
      });
    },
    updatePassword({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios
          .post("userprofile/changePassword", body)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "updatePassword");
      });
    },

    getResources({ commit }, language) {
      return new Promise((resolve, reject) => {
        axios
          .get(`resources/template?language=${language}`)
          .then(resp => {
            const result = resp.data;
            commit("SET_RESOURCES", { resources: result, language: language });
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getPrinterConfiguration({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("userprofile/printer")
          .then(resp => {
            const result = resp.data.result;
            commit("SET_PRINTER_CONFIGURATION", result);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    setPrinterConfiguration({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios
          .put("userprofile/printer", body)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "setPrinterConfiguration");
      });
    },

    setSeeUpgradePurchase({ commit }, toSee = true) {
      return new Promise(resolve => {
        commit("SET_SEE_UPGRADE_PURCHASE", toSee);
        resolve();
      });
    },

    setMembershipPlanName({ commit }, toSee = true) {
      return new Promise(resolve => {
        commit("SET_MEMBERSHIP_PLAN_NAME", toSee);
        resolve();
      });
    },

    setMembershipPlanCode({ commit }, toSee = true) {
      return new Promise(resolve => {
        commit("SET_MEMBERSHIP_PLAN_CODE", toSee);
        resolve();
      });
    },
    getQRCodeUserDevice({ commit }, params) {
      return new Promise((resolve, reject) => {
        const alias = encodeURI(params.alias);
        axios
          .get(`userprofile/qrcode/${params.requestId}?alias=${alias}`)
          .then(resp => {
            resolve(resp.data);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "getQRCodeUserDevice");
      });
    },
    getDeepLinkUserDevice({ commit }, params) {
      return new Promise((resolve, reject) => {
        const alias = encodeURI(params.alias);
        axios
          .get(`userprofile/deeplink/${params.requestId}?alias=${alias}`)
          .then(resp => {
            resolve(resp.data);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "getDeepLinkUserDevice");
      });
    },
    //PSC
    createNewPSCItem({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios
          .post("psc/documentset", body, {
            params: { paralelSigning: body.paralelSigning }
          })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "createNewPSCItem");
      });
    },

    createNewPSCFlowSimpleItem({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios
          .post("psc/documentset/flow/simple", body, {
            params: { paralelSigning: body.paralelSigning }
          })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "createNewPSCFlowSimpleItem");
      });
    },

    getHistoricPSCItems({ commit }, urlParams) {
      var params = new URLSearchParams([
        ["limit", urlParams.limit],
        ["offset", urlParams.offset]
      ]);

      if (urlParams.itemFilteringDates.length === 2) {
        params.append("FromDateTime", urlParams.itemFilteringDates[0]);
        params.append("ToDateTime", urlParams.itemFilteringDates[1]);
      }

      if (urlParams.itemFilteringReference) {
        params.append("reference", urlParams.itemFilteringReference);
      }

      if (urlParams.itemSearchById) {
        params.append("documentSetId", urlParams.itemSearchById);
      }

      if (urlParams.itemSearchByName) {
        params.append("DocumentName", urlParams.itemSearchByName);
      }

      if (urlParams.RecipientEmail) {
        params.append("RecipientEmail", urlParams.RecipientEmail);
      }

      if (urlParams.RecipientPhoneNumber) {
        params.append("RecipientPhoneNumber", urlParams.RecipientPhoneNumber);
      }

      if (urlParams.RecipientCardId) {
        params.append("RecipientCardId", urlParams.RecipientCardId);
      }

      if (urlParams.DocumentSetName) {
        params.append("DocumentSetName", urlParams.DocumentSetName);
      }

      if (urlParams.RecipientName) {
        params.append("RecipientName", urlParams.RecipientName);
      }

      if (
        urlParams.itemFilteringStatus &&
        urlParams.itemFilteringStatus != 0 &&
        urlParams.itemFilteringStatus.length != 8
      ) {
        urlParams.itemFilteringStatus.forEach(status => {
          params.append("status", status);
        });
      }

      if (urlParams.teamTokenId) {
        params.append("teams", urlParams.teamTokenId);
      }

      return new Promise((resolve, reject) => {
        axios
          .get("psc/history", { params })
          .then(resp => {
            commit("SET_HISTORIC_PSC_ITEMS", resp.data);
            commit(
              "SET_COUNT_HISTORIC_PSC_ITEMS",
              Number(resp.headers["x-total-count"])
            );
            resolve();
          })
          .catch(err => {
            commit("SET_HISTORIC_PSC_ITEMS", []);
            commit("SET_COUNT_HISTORIC_PSC_ITEMS", 0);
            reject(err);
          });
      });
    },

    getCSVExport({ commit }, urlParams) {
      var params = new URLSearchParams([]);

      if (urlParams.itemFilteringDates.length === 2) {
        params.append("FromDateTime", urlParams.itemFilteringDates[0]);
        params.append("ToDateTime", urlParams.itemFilteringDates[1]);
      }

      if (urlParams.itemFilteringReference) {
        params.append("reference", urlParams.itemFilteringReference);
      }

      if (urlParams.itemSearchById) {
        params.append("documentSetId", urlParams.itemSearchById);
      }

      if (urlParams.itemSearchByName) {
        params.append("DocumentName", urlParams.itemSearchByName);
      }

      if (urlParams.RecipientEmail) {
        params.append("RecipientEmail", urlParams.RecipientEmail);
      }

      if (urlParams.RecipientPhoneNumber) {
        params.append("RecipientPhoneNumber", urlParams.RecipientPhoneNumber);
      }

      if (urlParams.RecipientCardId) {
        params.append("RecipientCardId", urlParams.RecipientCardId);
      }

      if (urlParams.DocumentSetName) {
        params.append("DocumentSetName", urlParams.DocumentSetName);
      }

      if (urlParams.RecipientName) {
        params.append("RecipientName", urlParams.RecipientName);
      }

      if (
        urlParams.itemFilteringStatus &&
        urlParams.itemFilteringStatus != 0 &&
        urlParams.itemFilteringStatus.length != 8
      ) {
        urlParams.itemFilteringStatus.forEach(status => {
          params.append("status", status);
        });
      }

      if (urlParams.teamTokenId) {
        params.append("teams", urlParams.teamTokenId);
      }

      return new Promise((resolve, reject) => {
        axios
          .get("data/Extract/Csv", { params, responseType: "blob" })
          .then(resp => {
            let blob = new Blob([resp.data], { type: "text/csv" });
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            commit("SET_LAST_USER_ACTION", "downloadCSV");
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    createNewPSCFlowItem({ commit }, flow) {
      return new Promise((resolve, reject) => {
        axios
          .post("psc/documentset/flow", flow)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "createNewPSCFlowItem");
      });
    },

    acceptMultipleMyDocuments({ commit }, documentSetIds) {
      return new Promise((resolve, reject) => {
        axios
          .put("MyDocuments/AcceptMultiple", documentSetIds)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "acceptMultipleMyDocuments");
      });
    },

    clearMyDocuments({ commit }) {
      commit("SET_HISTORIC_MY_DOCUMENTS_ITEMS", []);
    },

    getMyDocuments({ commit }, urlParams) {
      var params = new URLSearchParams([
        ["limit", urlParams.limit],
        ["offset", urlParams.offset]
      ]);

      if (
        urlParams.itemFilteringDocumentStatus &&
        urlParams.itemFilteringDocumentStatus != 0 &&
        urlParams.itemFilteringDocumentStatus.length != 8
      ) {
        urlParams.itemFilteringDocumentStatus.forEach(status => {
          params.append("documentSetStatus", status);
        });
      }

      if (
        urlParams.itemFilteringRecipientStatus &&
        urlParams.itemFilteringRecipientStatus != 0 &&
        urlParams.itemFilteringRecipientStatus.length != 8
      ) {
        urlParams.itemFilteringRecipientStatus.forEach(status => {
          params.append("recipientActionStatus", status);
        });
      }

      if (urlParams.reference) {
        params.append("reference", urlParams.reference);
      }

      if (
        urlParams.recipientAuthenticationType ||
        urlParams.recipientAuthenticationType === 0
      ) {
        params.append(
          "recipientAuthenticationType",
          urlParams.recipientAuthenticationType
        );
      }

      if (urlParams.recipientActionType) {
        urlParams.recipientActionType.forEach(recipientActionType => {
          params.append("recipientActionType", recipientActionType);
        });
      }

      return new Promise((resolve, reject) => {
        axios
          .get("mydocuments", { params })
          .then(resp => {
            commit("SET_HISTORIC_MY_DOCUMENTS_ITEMS", resp.data);
            commit(
              "SET_COUNT_MY_DOCUMENTS_ITEMS",
              Number(resp.headers["x-total-count"])
            );
            resolve(resp);
          })
          .catch(err => {
            commit("SET_HISTORIC_MY_DOCUMENTS_ITEMS", []);
            commit("SET_COUNT_MY_DOCUMENTS_ITEMS", 0);
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "getMyDocuments");
      });
    },

    getDetailPSCItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`psc/documentset/${id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "getDetailPSCItem");
      });
    },

    getDetailMyDocumentItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`mydocuments/info/${id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "getDetailMyDocumentItem");
      });
    },

    getAuditTrailPSCItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`psc/documentset/audittrail/${id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "getAuditTrailPSCItem");
      });
    },

    getAuditTrailSFAItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`signFromApp/audittrail/${id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "getAuditTrailSFAItem");
      });
    },

    getErrorPSCItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`psc/documentset/error/${id}`)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "getErrorPSCItem");
      });
    },

    resendPSCItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `psc/documentset/resend/${id}`,
          method: "PUT"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "resendPSCItem");
      });
    },

    cancelPSCItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `psc/documentset/cancel/${id}`,
          method: "PUT"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "cancelPSCItem");
      });
    },

    downloadPSCItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `psc/documentset/document/${id}`,
          method: "GET",
          responseType: "blob"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "downloadPSCItem");
      });
    },

    removeDocuments({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `psc/documentset/purge/${id}`,
          method: "POST"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "removeDocuments");
      });
    },

    downloadDocumentFromDocumentSet({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios({
          url: `psc/documentset/document/${params.documentSetId}/${params.documentId}`,
          method: "GET",
          responseType: "blob"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "downloadDocumentFromDocumentSet");
      });
    },

    downloadPSCEvidenceItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `psc/documentset/evidences/${id}`,
          method: "GET",
          responseType: "blob"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "downloadPSCEvidenceItem");
      });
    },

    downloadAttachmentItem({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios({
          url: `psc/documentset/attachment/${params.documentSetId}/${params.attachmentId}`,
          method: "GET",
          responseType: "blob"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "downloadAttachmentItem");
      });
    },

    //SIGN FROM APP
    getHistoricSignFromAppItems({ commit }, urlParams) {
      var params = new URLSearchParams([
        ["limit", urlParams.limit],
        ["offset", urlParams.offset]
      ]);
      if (urlParams.itemFilteringDates.length === 2) {
        params.append("FromDateTime", urlParams.itemFilteringDates[0]);
        params.append("ToDateTime", urlParams.itemFilteringDates[1]);
      }
      if (
        urlParams.itemFilteringStatus != 0 &&
        urlParams.itemFilteringStatus.length != 8
      ) {
        urlParams.itemFilteringStatus.forEach(status => {
          params.append("status", status);
        });
      }
      return new Promise((resolve, reject) => {
        axios
          .get("signfromapp/history", { params })
          .then(resp => {
            commit("SET_HISTORIC_SIGN_FROM_APP_ITEMS", resp.data);
            commit(
              "SET_COUNT_SIGN_FROM_APP_ITEMS",
              Number(resp.headers["x-total-count"])
            );
            resolve(resp);
          })
          .catch(err => {
            commit("SET_HISTORIC_SIGN_FROM_APP_ITEMS", []);
            commit("SET_COUNT_SIGN_FROM_APP_ITEMS", 0);
            reject(err);
          });
      });
    },

    downloadSignFromAppItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `signfromapp/document/${id}`,
          method: "GET",
          responseType: "blob"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "downloadSignFromAppItem");
      });
    },

    cancelSignFromAppItem({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `signfromapp/document/cancel/${id}`,
          method: "PUT"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "cancelSignFromAppItem");
      });
    },

    createNewSignFromAppItem({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios
          .post("signfromapp", body)
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
        commit("SET_LAST_USER_ACTION", "createNewSignFromAppItem");
      });
    }
  },

  getters: {
    havePlanWriteMode: () => {
      return true; //state.userLevel === PLAN_WRITE_MODE;
    },

    historicCountPSCItems: state => {
      return state.countHistoricPSCItems;
    },

    bulkDownloadItems: state => {
      return state.countBulkDownloadItems;
    },

    countCorrespondenceCombinationItems: state => {
      return state.countCorrespondenceCombinationItems;
    },

    correspondenceCombinationItems: state => {
      return state.correspondenceCombinationItems;
    },

    historicCountFlowItems: state => {
      return state.countFlowItems;
    },

    historicCountSignFromAppItems: state => {
      return state.countSignFromAppItems;
    },

    historicMyDocumentsItems: state => {
      return state.countMyDocumentsItems;
    },

    historicPSCItems: state => {
      return state.historicPSCItems;
    },
    historicSignFromAppItems: state => {
      return state.historicSignFromAppItems;
    },
    newPSCItemParticipants: state => {
      return state.newPSCItemParticipants;
    },
    flows: state => {
      return state.flows;
    },
    myCompany: state => {
      return state.myCompany;
    },
    companyTeams: state => {
      return state.companyTeams;
    },
    companyUsers: state => {
      return state.companyUsers;
    },
    activeTeamTokenId: state => {
      if (state.activeTeamTokenId) {
        return state.activeTeamTokenId;
      }
      if (state.user.userProfile) {
        if (state.user.userProfile.defaultTeamTokenId) {
          return state.user.userProfile.defaultTeamTokenId;
        } else if (state.user.userProfile.teams.length > 0) {
          return state.user.userProfile.teams[0].teamTokenId;
        }
      }
      return undefined;
    }
  }
});
