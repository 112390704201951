<template>
  <v-container fluid class="down-top-padding">
    <v-card>
      <v-form class="pa-2" id="add-credit-card-form">
        <v-col lg="12" sm="12" xl="12" xs="12">
          <h3 class="mt-3 mb-0">Artículo</h3>
          <div class="row purchase-item-card-content">
            <p class="col-md-4 text-body-2 mt-2 ml-2">
              {{
                `1 x ${purchasingItem.membershipPlanName} = ${
                  purchasingItem.price
                }€/${MonthOrYear(purchasingItem.monthAmount)}`
              }}
            </p>
            <UpgradePurchaseCard
              class="col-md-6 purchase-item-card"
              :idPlan="purchasingItem.membershipPlanCode"
              :title="purchasingItem.membershipPlanName"
              :recommended="
                purchasingItem.membershipPlanCode === recommendedPlan
              "
              :cost="{
                currency: purchasingItem.price >= 0,
                unit:
                  purchasingItem.price >= 0
                    ? parseInt(purchasingItem.price)
                    : 'Contáctenos',
                decimal: ParseFractionalPart(
                  purchasingItem.price.toString(),
                  purchasingItem.monthAmount
                )
              }"
              :purchaseProps="[
                `${purchasingItem.creditAmount} firmas`,
                `Renovación cada ${MonthOrYear(purchasingItem.monthAmount)}`,
                ParseTextCost(purchasingItem.price)
              ]"
            >
            </UpgradePurchaseCard>
          </div>
          <v-divider class="purchase-item-divider"></v-divider>
          <h3 class="mt-3 mb-0">Seleccionar medio de pago</h3>
          <v-radio-group v-model="selectedCreditCard">
            <v-radio
              v-for="creditCard in availableCreditCards"
              :key="creditCard.id"
              :label="
                `${creditCard.cardHolderName} -- ${creditCard.creditCardType} **** **** **** ${creditCard.lastFourDigits}`
              "
              :value="creditCard.id"
            >
            </v-radio>
          </v-radio-group>
          <p class="text-body-2 mt-2 ml-2">Añadir nueva tarjeta</p>
          <v-text-field
            v-model="creditCardHolderName"
            label="Titular"
            outlined
            dense
          ></v-text-field>
          <v-card id="card-element" outlined class="pa-4">
            <!-- Elements will create input elements here -->
          </v-card>
          <!-- We'll put the error messages in this element -->
          <p class="text-body-2 mt-2" role="alert">{{ creditCardError }}</p>
          <div class="row no-gutters ml-3">
            <v-btn
              class="mr-6 mb-3"
              color="primary"
              type="submit"
              id="submit"
              :loading="addCardLoading"
              :disabled="
                loading ||
                  !creditCardCompleted ||
                  !!creditCardError ||
                  !creditCardHolderName
              "
              >Añadir tarjeta
            </v-btn>
            <v-btn
              color="primary"
              class="mb-3"
              :loading="checkoutPurchaseLoading"
              :disabled="!selectedCreditCard || loading"
              @click="purchase"
              >Realizar pedido
            </v-btn>
            <v-btn
              class="ml-6 mb-3"
              color="primary"
              text
              @click="$emit('close')"
              >Cerrar
            </v-btn>
          </div>
        </v-col>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import Axios from "axios";
import settings from "../../settings";
import UpgradePurchaseCard from "../upgradePurchase/UpgradePurchaseCard.vue";
import { RECOMMENDED_PLAN } from "@/utils/constants";
import { ParseFractionalPart, MonthOrYear, ParseTextCost } from "@/utils/utils";

export default {
  name: "Checkout",
  data: () => ({
    page: { title: "Realizar pago" },
    ParseFractionalPart: ParseFractionalPart,
    MonthOrYear: MonthOrYear,
    ParseTextCost: ParseTextCost,
    breadcrumbs: [
      {
        text: "Planes de suscripción",
        to: { name: "Purchase" },
        disabled: false,
        link: true,
        exact: true
      },
      {
        text: "Realizar pago"
      }
    ],
    availableCreditCards: [],
    selectedCreditCard: null,
    loading: true,
    addCardLoading: false,
    checkoutPurchaseLoading: false,
    creditCardHolderName: null,
    stripe: null,
    creditCardElement: null,
    creditCardError: null,
    creditCardCompleted: false
  }),
  props: {
    purchasingItem: {
      type: Object,
      require: true
    }
  },
  components: {
    UpgradePurchaseCard
  },
  computed: {
    recommendedPlan() {
      return RECOMMENDED_PLAN;
    }
  },
  methods: {
    async updateCreditCardList() {
      const response = await Axios.get("purchase/creditCards");
      this.availableCreditCards = response.data ?? [];
      this.selectedCreditCard = this.availableCreditCards[0]?.id;
      this.creditCardElement?.clear();
    },
    async addCreditCard() {
      this.loading = true;
      const response = await Axios.get("purchase/setupCreditCardSecret");
      const clientSecret = response.data;
      const result = await this.stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: this.creditCardElement,
          billing_details: {
            name: this.creditCardHolderName
          }
        }
      });
      if (result.error) {
        this.creditCardError = result.error.message;
        this.$toasted.global.toast_error({
          message: "No se ha podido añadir la tarjeta."
        });
      } else {
        await this.updateCreditCardList();
        this.$toasted.global.toast_success({
          message: "Tarjeta añadida correctamente."
        });
        this.loading = false;
      }
      this.loading = false;
    },
    purchase() {
      this.loading = true;
      this.checkoutPurchaseLoading = true;
      Axios.post("purchase/newPackage", {
        planId: this.purchasingItem.membershipPlanCode,
        purchaseType: this.purchasingItem.monthAmount == 1 ? 1 : 2,
        creditCardId: this.selectedCreditCard
      })
        .then(() => {
          this.$emit("purchase");
          this.$toasted.global.toast_success({
            message: "Compra realizada correctamente."
          });
        })
        .catch(e => {
          console.error(e);
          this.$toasted.global.toast_error({
            message: "No se ha podido realizar el pedido."
          });
        })
        .finally(() => {
          this.checkoutPurchaseLoading = false;
          this.loading = false;
        });
    }
  },
  async created() {
    await this.updateCreditCardList();
    this.loading = false;
  },
  async mounted() {
    this.stripe = window.Stripe(settings.stripeKey);
    const elements = this.stripe.elements();
    const card = elements.create("card");
    card.mount("#card-element");
    card.on("change", ({ complete, error }) => {
      this.creditCardError = error ? error.message : null;
      this.creditCardCompleted = complete;
    });
    this.creditCardElement = card;

    const form = document.getElementById("add-credit-card-form");
    form.addEventListener("submit", async ev => {
      this.addCardLoading = true;
      ev.preventDefault();
      await this.addCreditCard();
      this.addCardLoading = false;
    });
  }
};
</script>
