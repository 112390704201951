import Axios from "axios";
import uuidv4 from "uuid";

export default {
  install(Vue, router) {
    const authenticationManager = {
      // Loguea al usuario y le redirige a la url indicada
      // Si no se indica url de retorno se toma la actual
      signIn: returnUrl => {
        returnUrl = returnUrl ?? router.app.$route.path;
        window.location.href = `/auth/login?returnUrl=${returnUrl}`;
      },
      // Desloguea al usuario y le redirige a la url indicada
      // Si no se indica url de retorno se toma la home
      signOut: returnUrl => {
        returnUrl = returnUrl ?? "/";
        window.location.href = `/auth/logout?returnUrl=${returnUrl}`;
      },
      // Comprueba que el usuario esté autenticado, si no es así,
      // muestra la pantalla de login para su autenticación
      ensureAuthenticated: async to => {
        if (!this._authenticated) {
          return new Promise((resolve, reject) => {
            Axios({
              url: "/auth/info",
              method: "GET",
              returnUrl: to.path
            })
              .then(response => {
                if (response) {
                  this._authenticated = true;
                  window.DoLogIn();
                  resolve();
                }
              })
              .catch(err => {
                window.toasted.global.toast_full_error({
                  message:
                    "No se ha podido iniciar sesión, por favor inténtelo de nuevo pasados unos minutos"
                });
                console.log(err.response.data);
                reject();
              });
          });
        }
      }
    };

    // Exponemos los métodos de atutenticación a través de la propiedad $auth
    // del componente de aplicación
    Vue.prototype.$auth = authenticationManager;

    // Peticiones activas
    const inProgressRequests = [];
    // Cola de peticiones para reintentar
    const requestsToRetry = [];

    // Vuelve a lanzar las peticiones que se encuentran en la
    // cola de peticiones para reintentar
    function retryRequests() {
      while (requestsToRetry.length > 0) {
        const retryItem = requestsToRetry.shift();
        Axios(retryItem.request)
          .then(result => retryItem.resolve(result))
          .catch(e => retryItem.reject(e));
      }
    }

    // Añade una petición a la cola de peticiones para reintentar.
    // Devuelve una promesa que se resolverá al reintentar la petición.
    function retry(request) {
      return new Promise((resolve, reject) => {
        request._retry = true;
        requestsToRetry.push({
          request: request,
          resolve: resolve,
          reject: reject
        });
      });
    }

    // Inicia una petición
    // Se asigna un identificador único para la petición y se
    // agrega éste a la lista de peticiones activas
    function initRequest(request) {
      const uid = uuidv4();
      request._uid = uid;
      inProgressRequests.push(uid);
      return request;
    }

    // Al finalizar una petición se comprueba que haya finalizado correctamente
    // Si no es así, se analiza si debe reintentarse
    function endRequest(response, error) {
      const originalRequest = response.config;
      // Quitamos la petición de la lista de peticiones activas
      const requestIndex = inProgressRequests.indexOf(originalRequest._uid);
      if (requestIndex >= 0) {
        inProgressRequests.splice(requestIndex, 1);
      }

      let result = null;

      if (response.status >= 200 && response.status <= 299) {
        // Si no se ha producido ningún error devuelve el resultado
        result = response;
      } else if (response.status === 401) {
        // Si se produce un error de autenticación se añade la petición a la cola de peticiones a reintentar.
        // Si se trata de un reintento fallido (._retry === true) se abre el diálogo para loguear al usuario.
        if (originalRequest._retry) {
          authenticationManager.signIn(originalRequest.returnUrl);
        } else {
          result = retry(originalRequest);
        }
      } else {
        // Si se ha producido cualquier otro error (no de autenticación) se devuelve
        result = Promise.reject(error);
      }

      // En caso de que la petición se haya completado con éxito (usuario autenticado)
      // o que no queden peticiones pendientes de resolver, se reintentan las peticiones
      // que se encuentran en la cola de reintentos
      if (
        inProgressRequests.length === 0 ||
        (response.status >= 200 && response.status <= 299)
      ) {
        retryRequests();
      }

      return result;
    }

    Axios.interceptors.request.use(request => initRequest(request));

    Axios.interceptors.response.use(
      response => endRequest(response),
      error => endRequest(error.response, error)
    );
  }
};
