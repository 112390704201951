export const PIXEL_RATIO = window.devicePixelRatio || 1;
export const VIEWPORT_RATIO = 0.98;
export const RECOMMENDED_PLAN = "FOOFFICEMONTH";
export const PLAN_WRITE_MODE = "Corporate";
export const PURCHASE_ITEM_TYPE = {
  NONE: 0,
  CREDITS_PACKAGE: 1,
  SUBSCRIPTION: 2
};

export function DefaultMailTemplateData() {
  return {
    canceledEmailBody:
      "Hola {RecipientName},\nEl sobre enviado por {SenderName} ({SenderMail}) ha sido cancelado.",
    canceledEmailSubject:
      "{DocumentSetName} - firmar.online - con fecha {CreationDate}",
    completedEmailBody:
      "Hola {RecipientName},\nEl sobre enviado por {SenderName} ({SenderMail}) ha finalizado con éxito.",
    completedEmailSubject:
      "{DocumentSetName} - firmar.online - con fecha {CreationDate}",
    expiredEmailBody:
      "Hola {RecipientName},\nHa finalizado el plazo para acceder al sobre enviado por {SenderName} ({SenderMail}).",
    expiredEmailSubject:
      "{DocumentSetName} - firmar.online - con fecha {CreationDate}",
    notificationEmailBody:
      "Hola {RecipientName},\n{SenderName} ({SenderMail}) ha enviado el sobre anexo.",
    notificationEmailSubject:
      "{DocumentSetName} - firmar.online - con fecha {CreationDate}",
    rejectedEmailBody:
      "Hola {RecipientName},\nEl sobre enviado por {SenderName} ({SenderMail}) ha sido rechazado.",
    rejectedEmailSubject:
      "{DocumentSetName} - firmar.online - con fecha {CreationDate}",
    rememberEmailBody:
      "Hola {RecipientName},\nRecuerda que el sobre enviado por {SenderName} ({SenderMail}) sigue esperando para {txtFirma}.\nPor favor, pulse en el siguiente botón para acceder:",
    rememberEmailSubject:
      "{DocumentSetName} - firmar.online - con fecha {CreationDate}",
    signEmailBody:
      "Hola {RecipientName},\n{SenderName} ({SenderMail}) ha enviado el siguiente sobre para {palabraFirma}.\nPor favor, pulse en el siguiente botón para acceder:",
    signEmailSubject:
      "{DocumentSetName} - firmar.online - con fecha {CreationDate}",
    footerEmail: "",
    b64LogoContent: "",
    b64LogoFooterContent: "",
    fromSms: "FirmaOnline",
    signedSms: "Pulse en el siguiente enlace {url} para acceder al documento"
  };
}
