<template>
  <v-list-group
    :group="group"
    @click="onClick()"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    :active-class="`primary white--text`"
    :ref="'list-group'"
  >
    <template v-slot:activator>
      <v-list-item-icon v-if="item.localIcon" class="localIcon">
        <v-icon :src="item.localIcon" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <BaseItemSubGroup
        v-if="child.children"
        :key="`sub-group-${i}`"
        :item="child"
        class="second-dd"
      />

      <BaseItem
        :ref="`sub-group-item-${i}`"
        v-else
        :key="`item-${i}`"
        :item="child"
        text
      />
    </template>
  </v-list-group>
</template>

<script>
// Utilities
import kebabCase from "lodash/kebabCase";

export default {
  name: "BaseItemGroup",

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: []
      })
    },
    subGroup: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    children() {
      return this.item.children.map(item => ({
        ...item,
        to: !item.to ? undefined : `${this.item.group}/${item.to}`
      }));
    },
    group() {
      return this.genGroup(this.item.children);
    }
  },

  methods: {
    onClick() {
      const that = this;
      setTimeout(() => {
        if (that.item.launchItem && that.$refs["list-group"].isActive) {
          const firstItem =
            that.$refs[`sub-group-item-${that.item.launchItem}`];
          if (firstItem && firstItem[0]) firstItem[0].$el.click();
        }
      }, 0);
    },
    genGroup(children) {
      return children
        .filter(item => item.to)
        .map(item => {
          const parent = item.group || this.item.group;
          let group = `${parent}/${kebabCase(item.to)}`;

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`;
          }

          return group;
        })
        .join("|");
    }
  }
};
</script>

<style></style>
