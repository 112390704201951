import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";

import settings from "../settings";

export default {
  install(Vue) {
    let connection = null;
    let startedPromise = null;
    let connected = false;
    let reconnectDelay = 5000;

    const notificationsHub = new Vue(); // use new Vue instance as an event bus

    function createEvents(conn) {
      // Forward hub events through the event, so we can listen for them in the Vue components
      conn.on("CreditsConsumed", () => {
        notificationsHub.$emit("credits-consumed");
      });

      conn.on("Trace", (value, message) => {
        notificationsHub.$emit("trace", [value, message]);
      });

      conn.on("SignCompleted", fileToken => {
        notificationsHub.$emit("sign-completed", fileToken);
      });

      conn.on("LogMessage", message => {
        notificationsHub.$emit("log-message", message);
      });
    }

    notificationsHub.isConnected = () => {
      return connected;
    };

    // Method for open connection
    notificationsHub.connect = () => {
      // You need to call connection.start() to establish the connection
      // the client wont handle reconnecting for you! Docs recommend listening onclose
      // and handling it there. This is the simplest of the strategies
      function start() {
        console.log("Starting notificationsHub connect");
        if (connection) {
          console.log("connection != null");
          console.log("calling connection stop");
          connection.stop();
        }
        connection = new HubConnectionBuilder()
          .withUrl(`${settings.baseUrl}signalR`)
          .configureLogging(LogLevel.Information)
          .build();
        connection.onclose(() => {
          console.log("SignalR connection disconnected");
          setTimeout(() => {
            start();
            notificationsHub.$emit("disconnected", true);
          }, 2000);
        });
        createEvents(connection);

        startedPromise = connection
          .start()
          .then(() => {
            connected = true;
            reconnectDelay = 5000;
          })
          .catch(() => {
            connected = false;
            let timespan = reconnectDelay;
            console.log(
              "SignalR error connecting: Next try in " + timespan + " ms"
            );
            reconnectDelay *= 1.5;
            return new Promise((resolve, reject) =>
              setTimeout(
                () =>
                  start()
                    .then(resolve)
                    .catch(reject),
                timespan
              )
            );
          });
        return startedPromise;
      }

      start();
    };

    // Method for close connection
    notificationsHub.disconnect = () => {
      connected = false;
      console.log("dispatch notificationsHub disconnect");
      if (connection) {
        console.log("calling connection stop");
        connection.stop();
      }
    };

    notificationsHub.addEventListener = (eventName, handler) => {
      if (connection) {
        connection.on(eventName, handler);
      }
    };

    notificationsHub.removeEventListener = eventName => {
      if (connection) {
        connection.off(eventName);
      }
    };

    // Add the hub to the Vue prototype, so every component can listen to
    // events or send new events using this.$notificationsHub
    Vue.prototype.$notificationsHub = notificationsHub;
  }
};
