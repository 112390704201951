<template>
  <div :class="{ 'update-purchase-card': true, recommended: recommended }">
    <div class="update-purchase-header">
      <h3>{{ title }}</h3>
    </div>
    <div class="update-purchase-body">
      <div class="update-purchase-body-info">
        <span
          :class="{ 'price-table__recommended': true, invisible: !recommended }"
          >Recomendado</span
        >
        <div class="price-table__price">
          <div
            v-if="cost && cost.currency"
            class="price-table__currency__container"
          >
            <span class="price-table__currency currency--before">€</span>
          </div>
          <span
            :class="{
              'price-table__integer-part': true,
              'no-cost': !(cost && cost.currency)
            }"
            >{{ cost && cost.unit }}</span
          >
          <div
            v-if="cost && cost.decimal && cost.currency"
            class="price-table__after-price"
          >
            <span class="price-table__fractional-part">{{
              cost && cost.decimal
            }}</span>
          </div>
        </div>
        <div></div>
        <div></div>
      </div>
      <v-btn
        :class="{ 'purchase-button': true, disabled: current }"
        @click="$emit('purchase', idPlan)"
        >{{
          current ? "Plan actual" : cost.currency ? "Contratar" : "Contactar"
        }}</v-btn
      >
      <hr />
      <template v-for="(prop, i) in purchaseProps">
        <span
          class="update-purchase-body-prop no-gutters row"
          :key="`update-purchase-body-prop-${title}-${i}`"
          >{{ prop }}</span
        >
      </template>
    </div>
  </div>
</template>
<script>
export default {
  mounted: () => {},
  name: "UpgradePurchaseCard",
  components: {},
  props: {
    idPlan: {
      type: String,
      require: true
    },
    title: {
      type: String,
      require: true
    },
    current: {
      type: Boolean,
      default: false
    },
    recommended: {
      type: Boolean,
      default: false
    },
    purchaseProps: {
      type: Array,
      required: true
    },
    cost: {
      type: Object,
      require: true
    }
  },
  data: () => ({}),
  computed: {},
  methods: {}
};
</script>

<style lang="scss"></style>
