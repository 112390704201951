import Vue from "vue";
import VueToasted from "vue-toasted";

Vue.use(VueToasted, {
  iconPack: "mdi" // set your iconPack, defaults to material. material|fontawesome|custom-class
});

/** ERROR */

// options to the toast
let errorToastOptions = {
  type: "error",
  icon: "alert-circle-outline",
  position: "bottom-center",
  duration: 6000,
  className: "toast-center"
};

// register the toast with the custom message
Vue.toasted.register(
  "toast_error",
  payload => {
    // if there is no message passed show default message
    if (!payload.message) {
      return "No se ha podido realizar la acción solicitada";
    }

    // if there is a message show it with the message
    return "Error: " + payload.message;
  },
  errorToastOptions
);

/** FULL ERROR */

// options to the toast
let fullErrorToastOptions = {
  type: "error",
  icon: "alert-circle-outline",
  position: "top-center",
  duration: null
};

// register the toast with the custom message
Vue.toasted.register(
  "toast_full_error",
  payload => {
    // if there is no message passed show default message
    if (!payload.message) {
      return "No se ha podido realizar la acción solicitada";
    }

    // if there is a message show it with the message
    return "Error: " + payload.message;
  },
  fullErrorToastOptions
);

/** SUCESS */

let successToastOptions = {
  type: "success",
  icon: "check",
  position: "bottom-center",
  duration: 2500,
  className: "toast-center"
};

// register the toast with the custom message
Vue.toasted.register(
  "toast_success",
  payload => {
    if (!payload.message) {
      return "La acción se ha realizado correctamente";
    }
    return "Acción realizada: " + payload.message;
  },
  successToastOptions
);

/** INFO */

let infoToastOptions = {
  type: "info",
  icon: "alert-circle-outline",
  position: "bottom-center",
  duration: 6500,
  className: "toast-center"
};

// register the toast with the custom message
Vue.toasted.register(
  "toast_info",
  payload => {
    if (!payload.message) {
      return "";
    }
    return "Aviso: " + payload.message;
  },
  infoToastOptions
);

window.toasted = Vue.toasted;

export default VueToasted;
