<template>
  <v-container fluid white class="down-top-padding">
    <h1 class="text-align-center">
      <v-icon medium :color="'green'" class="mb-1 check-purchase"
        >mdi-check-bold</v-icon
      >
      Compra realizada con éxito
    </h1>
    <v-divider class="purchase-item-divider mb-5"></v-divider>
    <h4 class="font-weight-500 ml-5">
      Producto: {{ purchasingItem && purchasingItem.membershipPlanName }}
    </h4>
    <h4 class="font-weight-500 ml-5">
      Precio: {{ purchasingItem && purchasingItem.price }} &euro;
    </h4>
    <div class="w-100 mb-1 mr-3 text-align-right">
      <v-btn class="" color="primary" text @click="$emit('close')"
        >Cerrar
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Success",
  data: () => ({
    page: { title: "Compra realizada con éxito " }
  }),
  props: {
    purchasingItem: null
  }
};
</script>

<style></style>
