import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: { requiresAuthentication: false },
    name: "Home",
    component: () => import("@/views/authentication/Login")
  },
  {
    path: "/printer",
    redirect: "connect/printer",
    name: "printer",
    component: () => import("@/layouts/full-layout/ConnectLayout"),
    meta: { requiresAuthentication: true },
    children: [
      {
        name: "OldPrinter",
        path: "printer",
        component: () => import("@/views/printer/Printer")
      },
      {
        name: "OldPrinterSettings",
        path: "printersettings",
        component: () => import("@/views/printer/PrinterSettings")
      }
    ]
  },
  {
    path: "/connect",
    redirect: "connect/printer",
    name: "connect",
    component: () => import("@/layouts/full-layout/ConnectLayout"),
    meta: { requiresAuthentication: true },
    children: [
      {
        name: "Printer",
        path: "printer",
        component: () => import("@/views/printer/Printer")
      },
      {
        name: "PrinterSettings",
        path: "printersettings",
        component: () => import("@/views/printer/PrinterSettings")
      },
      {
        name: "Office",
        path: "office",
        component: () => import("@/views/office/Office")
      },
      {
        name: "OfficeSettings",
        path: "officesettings",
        component: () => import("@/views/office/OfficeSettings")
      }
    ]
  },
  {
    path: "/document-receiver",
    redirect: "connect/document-receiver",
    component: () => import("@/layouts/full-layout/LayoutWithoutDrawer"),
    meta: { requiresAuthentication: true },
    children: [
      {
        path: "/connect/document-receiver",
        name: "DocumentReceiver",
        component: () => import("@/views/complement/DocumentReceiver"),
        meta: { requiresAuthentication: true }
      }
    ]
  },
  {
    path: "/",
    redirect: "signatures/remote/sent",
    component: () => import("@/layouts/full-layout/Layout"),
    meta: { requiresAuthentication: true },
    children: [
      // Application
      {
        name: "DigitalSignature",
        path: "tools/digital-signature",
        props: true,
        component: () => import("@/views/tools/DigitalSignature")
      },
      {
        name: "DocumentsValidate",
        path: "tools/document-validation",
        props: true,
        component: () => import("@/views/tools/DocumentsValidate")
      },
      {
        name: "TimestampSignature",
        path: "tools/timestamp-signature",
        props: true,
        component: () => import("@/views/tools/TimestampSignature")
      },
      {
        name: "RemoteSignaturesSent",
        path: "signatures/remote/sent",
        props: true,
        component: () => import("@/views/signatures/remote/HistoricSignatures")
      },
      {
        name: "RemoteSignaturesReceived",
        path: "signatures/remote/received",
        props: true,
        component: () => import("@/views/signatures/remote/RequestsReceived")
      },
      {
        name: "RemoteFlows",
        path: "signatures/remote/flows",
        props: true,
        component: () => import("@/views/signatures/remote/RemoteFlows")
      },
      {
        name: "CorrespondenceCombination",
        path: "signatures/remote/correspondence-combination",
        props: true,
        component: () =>
          import("@/views/signatures/remote/CorrespondenceCombination")
      },
      {
        name: "BulkDownload",
        path: "signatures/remote/bulk-download",
        props: true,
        component: () => import("@/views/signatures/remote/BulkDownload")
      },
      {
        name: "RemoteSignaturesMultipleAccept",
        path: "signatures/remote/multi-accept",
        props: true,
        component: () =>
          import("@/views/signatures/remote/RemoteSignaturesMultiAccept")
      },
      {
        name: "FaceToFaceSignatures",
        path: "signatures/face-to-face",
        props: true,
        component: () =>
          import("@/views/signatures/face-to-face/FaceToFaceSignatures")
      },
      {
        name: "Settings",
        path: "settings",
        component: () => import("@/views/settings/Settings")
      },
      {
        name: "Profile",
        path: "profile",
        component: () => import("@/views/profile/Profile")
      },
      {
        name: "Customer",
        path: "my-company",
        component: () => import("@/views/customer/Customer")
      },
      {
        name: "PaymentMethods",
        path: "profile/payment-methods",
        component: () => import("@/views/profile/PaymentMethods")
      },
      // Purchase
      {
        name: "Purchase",
        path: "purchase",
        component: () => import("@/views/purchase/AvailablePlans")
      },
      {
        name: "Checkout",
        path: "purchase/checkout",
        component: () => import("@/views/purchase/Checkout")
      },
      {
        name: "Purchase-Success",
        path: "purchase/success",
        component: () => import("@/views/purchase/Success")
      }
    ]
  },
  {
    name: "NewDocumentSet",
    path: "/signatures/remote/new-multi",
    component: () => import("@/views/signatures/remote/NewDocumentSet"),
    meta: { requiresAuthentication: true },
    props: true
  },
  {
    name: "NewFaceToFaceSignature",
    path: "/signatures/face-to-face/new",
    component: () =>
      import("@/views/signatures/face-to-face/NewFaceToFaceSignature"),
    meta: { requiresAuthentication: true },
    props: true
  },
  { path: "*", redirect: "/signatures/remote/sent" }
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
  // base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  }
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.beforeEach(async (to, from, next) => {
  window.toasted.clear();
  if (to.matched.some(record => record.meta.requiresAuthentication)) {
    await router.app.$auth.ensureAuthenticated(to);
    next();
  } else {
    next();
  }
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
