<template>
  <v-app>
    <EnvironmentBanner></EnvironmentBanner>
    <FullDialog></FullDialog>
    <router-view />
  </v-app>
</template>

<script>
import FullDialog from "./layouts/full-layout/dialog/FullDialog";
import EnvironmentBanner from "./layouts/full-layout/banner/EnvironmentBanner";

export default {
  name: "App",
  components: {
    FullDialog,
    EnvironmentBanner
  }
};
</script>
